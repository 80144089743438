// ==========================================================================
// Page – FinList
// ==========================================================================

.finlist-table {
	thead {
		th:nth-child(2) {
			max-width: 300px;
		}

		th:nth-child(3), th:nth-child(5), th:nth-child(8) {
			width: 8%;
		}

		th:nth-child(4), th:nth-child(6) {
			width: 10%;
		}

		th:nth-child(7), th:nth-child(9) {
			width: 15%;
		}
	}

	tbody {
		tr {
			&.highlight {
				background-color: rgba($finetic-yellow-500, 0.3);
	
				&:hover {
					background-color: rgba($finetic-yellow-500, 0.2);
				}

				td:last-child {
					position: relative;
					overflow: hidden;

					&:after {
						content: "";
						position: absolute;
						width: 70px;
						height: 10px;
						background: $finetic-orange-500;
						top: 5px;
						right: -25px;
						transform: rotate(45deg);
					}
				}
			}

			&.finlist-low-turnover {
				> :not(:nth-child(1)):not(:nth-last-child(1)) {
					opacity: 0.4;
					filter: saturate(0.5);

					@include media-breakpoint-down(md) {
						& {
							opacity: 0.4;
						}
					}
				}
			}
		}

		td:nth-child(2) {
			max-width: 300px;

			div {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		td:nth-child(3), td:nth-child(5), td:nth-child(8) {
			width: 8%;
			text-align: right;
		}

		td:nth-child(4), td:nth-child(6) {
			width: 10%;
			text-align: right;
		}

		td:nth-child(7), td:nth-child(9) {
			width: 15%;
			text-align: center;
		}
	}
}

.finlist-overlay {
	@include media-breakpoint-down(md) {
		& {
			font-size: 100%;
		}
	}

	.offcanvas-body {
		.finlist-chart {
			height: 50vh!important;
		}

		.fundamental-data-table {
			font-size: 0.8em;
	
			> thead {
				> tr > {
					th {
						color: text-color();
						border-style: solid solid none solid;
					}
	
					th:nth-child(1) {
						width: 15%!important;
					}
	
					th:nth-child(2), th:nth-child(3), th:nth-child(4) {
						width: 20%!important;
					}
				}
			}
	
			> tbody {
				@media (min-width: 992px) {
					tr:hover {
						background-color: color(green, light);
					}	
				}
				
				&:before, &:after {
					height: 0!important;
				}
	
				> tr > {
					th:nth-child(1) {
						width: 5%!important;
					}
	
					td:nth-child(2) {
						width: 10%!important;
						white-space: nowrap;
					}
	
					td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6), td:nth-child(7), td:nth-child(8) {
						width: 10%!important;
					}
	
					th, td {
						padding-left: 1em!important;
						padding-right: 1em!important;
					}
				}
			}
		}

		.ccass-data-table {
			margin-bottom: 1em;
			font-size: 0.8em;
	
			> thead {
				> tr > {
					th {
						border-style: solid solid none solid;
					}

					th:nth-child(1) {
						width: 50%!important
					}

					th:nth-child(2), th:nth-child(3) {
						width: 25%!important;
					}
				}
			}
	
			> tbody {
				@media (min-width: 992px) {
					tr:hover {
						background-color: $finetic-gray-300;
					}	
				}
				
				&:before, &:after {
					height: 0!important;
				}
	
				> tr > {
					th:nth-child(1) {
						width: 10%!important;
					}
	
					td:nth-child(2) {
						width: 40%!important;
						white-space: nowrap;
					}
	
					td:nth-child(3), td:nth-child(4) {
						width: 25%!important;
					}
	
					th, td {
						padding: 0.5em 1em!important;
					}
				}
			}
		}
	
		.ccass-data-chart {
			width: 300px!important;
			height: 300px!important;
		}
	
		.ccass-information-date {
			color: #999999
		}

		.ccass-box {
			width: 20px;
			height: 20px;
		}
		
		.ccass-box-1 { background-color: #005394; }
		.ccass-box-2 { background-color: #A7FA42; }
		.ccass-box-3 { background-color: #FF7B00; }
		.ccass-box-4 { background-color: #0095A0; }
		.ccass-box-5 { background-color: #2BE3EA; }
		.ccass-box-6 { background-color: #005593; }
		.ccass-box-7 { background-color: #0099FF; }
		.ccass-box-8 { background-color: #417AAA; }
		.ccass-box-9 { background-color: #62BAFF; }
		.ccass-box-10 { background-color: #002A4A; }
	}
}

.sort-direction {
	color: $finetic-gray-500;

	.sort-active {
		color: $black;
	}

	.sort-arrow-up {
		cursor: pointer;
	}

	--lm-switch-border-color-checked: var(--lm-switch-border-color);
	--lm-switch-bg-color-checked: var(--lm-switch-bg-color);
	--lm-switch-slider-bg-color-checked: var(--lm-switch-slider-bg-color);
}

.finlist-stock-card {
	background-color: $white;
	border-radius: 5px;
	position: relative;

	&.expanded {
		background: none;
	}

	&.highlight {
		position: relative;
		overflow: hidden;
	}
	
	&.highlight:after {
		content: "";
		position: absolute;
		width: 90px;
		height: 20px;
		background: $finetic-orange-500;
		top: -4px;
		right: -48px;
		transform: rotate(45deg);
	}

	.ticker {
		.stock-code {
			font-weight: bold;
		}

		.stock-name {
			font-size: 0.9em;
		}
	}

	.details {
		font-size: 0.8em;
		cursor: pointer;

		&.expanded {
			cursor: default;
		}

		.label {
			color: rgba(black, 0.6);
		}

		.tab {
			cursor: pointer;

			&.active > b {
				border-bottom: 2px solid $finetic-blue-500;
				padding: 0em 0.5em 0.1em 0.5em;
			}
		}

		.fundamental-data-table, .ccass-data-table {
			font-size: 0.8em;

			table {
				border-collapse: separate;
				border: none;

				thead th {
					border: none;
				}
			}
		}

		.ccass-data-row {
			.ccass-box {
				max-width: 7px;
				height: 30px;
			}
			
			.ccass-name {
				flex: 3;
				line-height: 110%;
			}

			.ccass-data {
				flex: 1;
				text-align: right;
				line-height: 110%;
			}

			.ccass-data-chart {
				width: 150px!important;
				height: 150px!important;
			}
		}
	}

	.expand-chevron {
		color: $finetic-gray-500;
	}

	&.finlist-low-turnover {
		.ticker, .details {
			opacity: 0.4;
		}
	}
}

.finlist-chart {
	max-width: 100%;
	height: 100%;
}

.stock-info {
	max-width: 960px;
}

.watchlist-icon {
	cursor: pointer;
}

.active-watchlist-icon {
	color: #3D73DD;
}

.inactive-watchlist-icon {
	color: $finetic-gray-500;
}

.current-watchlist-stock {
	span {
		padding-top: 0.2em;
	}
}

.mask-finlist-stock {
	-webkit-filter: blur(4px);
	filter: blur(4px);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.forbidden-overlay {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	top: 15em;
	padding-bottom: 1.5em;
	z-index: 5;

	.forbidden {
		@include bg-blur-glass;
		background-color: rgba(white, 0.7);
		margin: 0 1em;
		padding: 0 2em;
		padding-bottom: 1.5em;
		border-radius: 5px;
		-moz-box-shadow: 0 .4rem .8rem rgba(0, 0, 0, .15);
		-webkit-box-shadow: 0 .4rem .8rem rgba(0, 0, 0, .15);
		box-shadow: 0 .4rem .8rem rgba(0, 0, 0, .15);
	}

	.forbidden-img-wrapper {
		display: none;
	}
}
