// ==========================================================================
// Page – Education
// ==========================================================================

.video-list {
	flex-wrap: wrap;

	.sunday-club-card {
		color: $black;
		text-decoration: none;
	}

	.video {
		cursor: pointer;

		.video-img-wrapper {
			position: relative;

			.play-button {
				visibility: hidden;
				font-size: 4em;
				color: $finetic-blue-500;
			}
		}
	
		.video-info-wrapper {
			.video-duration {
				font-size: 0.9em;
				color: $finetic-gray-500;
			}
		}

		&:hover {
			.play-button {
				visibility: visible;
			}
		}
	}
}

.education-article {
	@include media-breakpoint-up(lg) {
		img {
			width: 50%;
		}
	}

	.education-link {
		text-decoration: underline;
		cursor: pointer;
	}
}
