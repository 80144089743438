// ==========================================================================
// Page – Stock Movement
// ==========================================================================

.market-movers-last-updated {
	font-size: 0.8em;
	color: $finetic-gray-700;
}

.enhanced-market-movers-slider::-webkit-slider-thumb {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)!important;
}

.enhanced-market-movers-slider::-moz-range-thumb {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)!important;
}

.enhanced-market-movers-slider::-ms-thumb {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)!important;
}

.stock-movement-table {
	margin-bottom: 1em;
	font-size: 0.8em;

	> thead {
		th:not(:first-child) {
			text-align: right;
		}

		th:nth-child(3) {
			padding-right: 1em!important;
		}

		th:nth-child(4) {
			width: 17.5%!important;
			min-width: 30px;
		}
	}

	> tbody {
		td {
			vertical-align: middle;
		}

		td:not(:first-child) {
			text-align: right;
		}

		td:nth-child(3) {
			padding-right: 1em!important;
		}

		td:nth-child(4) {
			width: 17.5%!important;
			min-width: 30px;
		}
	}

	.badge {
		font-size: 100%;
	}
}

.stock-movement-growth {
	padding: 5px;
	margin-left: 0.5em;
	border-radius: 5px;
	text-align: right;
	width: 100%;

	span {
		color: $white!important;
	}

	&.positive {
		background-color:  $positive-color;
	}

	&.negative {
		background-color: $negative-color;
	}
}

.stock-movement-row {
	font-size: 0.8em;

	.badge {
		font-size: 100%;
	}
}

.market-movers-change-desc {
	font-size: 0.8em;
}
