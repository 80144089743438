// ==========================================================================
// Page – Interactive Chart
// ==========================================================================

.more-tools-link {
	color: $black;
	text-decoration: none;

	.more-tools-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
	}
}