// ==========================================================================
// Base – Colors
// ==========================================================================

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$finetic-green-300: #CAFC8D;
$finetic-green-500: #A7FA41;
$finetic-green-700: #649627;

$finetic-orange-300: #FFB066;
$finetic-orange-500: #FF7C00;
$finetic-orange-700: #994A00;

$finetic-blue-100: #C2D6E6;
$finetic-blue-300: #6698BE;
$finetic-blue-400: #3D73DD;
$finetic-blue-500: #005493;
$finetic-blue-700: #003258;

$finetic-yellow-300: #FFF4CB;
$finetic-yellow-500: #FFD841;
$finetic-yellow-700: #7A671F;

$finetic-gray-300: #E9EDF1;
$finetic-gray-500: #B9BDC0;
$finetic-gray-700: #7B7D7F;

$finetic-dark-300: #636466;
$finetic-dark-500: #505152;
$finetic-dark-700: #242425;

$positive-color: #23C552;
$negative-color: #F84F31;

$finetic-theme-colors: (
	"finetic-blue": $finetic-blue-500,
	"finetic-orange": $finetic-orange-500,
	"finetic-green": $finetic-green-500,
	"finetic-yellow": $finetic-yellow-500,
	"finetic-light-gray": $finetic-gray-300,
	"finetic-gray": $finetic-gray-500,
	"finetic-dark": $finetic-dark-500,
	"positive": $positive-color,
	"negative": $negative-color,
);

$theme-colors: map-merge($theme-colors, $finetic-theme-colors);

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/helpers";

@import "bootstrap/scss/utilities/api";
