// ==========================================================================
// Component – Tabs
// ==========================================================================

.nav-tabs {
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: nowrap;

	.nav-item {
		white-space: nowrap;
	}
}
// .tablist {
// 	color: text-color(white);
// 	width: 100%;
// 	white-space: nowrap;
// 	overflow-x: scroll;
// 	-ms-overflow-style: none;  /* IE and Edge */
// 	scrollbar-width: none;  /* Firefox */

// 	&::-webkit-scrollbar {
// 		display: none;
// 	}

// 	.tab {
// 		margin: 0px 1em;
// 		display: inline-block;
// 		cursor: pointer;

// 		&:hover {
// 			border-bottom: 2px solid $finetic-blue-300;
// 		}

// 		&.active {
// 			border-bottom: 2px solid $finetic-blue-500;

// 			span {
// 				font-weight: bold;
// 			}
// 		}
// 	}
// }

// @media (max-width: 991.98px) {
// 	.tablist {
// 		font-size: 0.85em;
// 		margin-bottom: 0;

// 		b {
// 			font-weight: normal;
// 		}
// 	}
// }
