// ==========================================================================
// Component – Header
// ==========================================================================

#header-finetic {
	color: $white!important;
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */

	.navbar {
		padding-left: env(safe-area-inset-left);
		padding-right: env(safe-area-inset-right);
		padding-top: max(env(safe-area-inset-top), var(--bs-navbar-padding-y));
		
		@include media-breakpoint-down(md) {
			box-shadow: $box-shadow;
		}
	}

	.logo {
		height: 30px;
	}

	.sidebar-toggle {
		font-size: 1.5em;
		color: $white;
	}

	.navbar-title {
		font-size: 2em;
		font-weight: 300;

		@include media-breakpoint-down(md) {
			font-size: 1.25em;
		}
	}

	.genuine-amelia-icon {
		border: 2px solid $white;
		border-radius: 5px;
		height: 25px;
	
		.icon-text {
			text-transform: uppercase;
			line-height: 100%;
			font-size: 0.5em;
			padding-top: 2px;
		}
	}
	
	@media (max-width: 991.98px) {
		.genuine-amelia-icon {
			margin-top: 1px;
		}
	}
}
