// ==========================================================================
// Component – Plan
// ==========================================================================

.plan {
	background-color: $white;
	border-radius: 10px;
	padding-top: 1em;

	@media (max-width: 991.98px) {
		font-size: 13px;
	}

	p {
		margin: 0;
		padding: 5px 0;
	}

	.plan-title {
		font-size: 17px;
	}

	.plan-price-sm {
		.odometer, sub {
			font-size: 13px;
		}
	}

	.plan-feature {
		color: rgba($black, 0.6);
	}

	.odometer-digit-spacer {
		padding: 0 0.15rem;
	}

	.manage-subscription {
		text-decoration: none;
	}
}

.plan-highlight {
	box-shadow: 0px 10px 20px rgba($black, 0.3);
}

.button-wrapper {
	padding-top: 2em;
}

.odometer {
	font-size: 2.5em;
}

@media (max-width: 767.98px) {
	.button-wrapper {
		padding-top: 0;
	}

	.odometer {
		font-size: 2em;
	}
}

.discount-note {
	border-radius: 15px;
	line-height: 2em;
	font-size: 0.8em;
}

.discount-note-hide {
	opacity: 0;
}
