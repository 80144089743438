// ==========================================================================
// Component – Offcanvas
// ==========================================================================

.offcanvas {
	--bs-offcanvas-width: auto!important;
	min-width: 40vw;
	max-width: 60vw;
	font-size: clamp(1em, 0.7vw, 0.7vw);
	padding-top: env(safe-area-inset-top);
	padding-bottom: env(safe-area-inset-bottom);
	box-shadow: 10px 10px 20px $finetic-blue-700;

	@include media-breakpoint-down(md) {
		--bs-offcanvas-width: 100%!important;
		max-width: 100%;
	}
}
