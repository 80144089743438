// ==========================================================================
// Global Styles
// ==========================================================================

body {
	height: 100vh;
	background-color: $finetic-blue-500;
	padding-top: env(safe-area-inset-top);

	@include media-breakpoint-down(md) {
		background-color: $finetic-gray-300;
	}

	#main-container {
		padding-top: 54px;

		#main-content {
			background-color: $finetic-gray-300;
			border-top-left-radius: 5px;
			height: calc(100vh - 54px - env(safe-area-inset-bottom));
			padding-left: env(safe-area-inset-left);
			padding-right: env(safe-area-inset-right);
			overflow-y: auto;
			
			@include media-breakpoint-down(md) {
				height: unset;
				padding-bottom: calc(72px + env(safe-area-inset-bottom));
				border-top-left-radius: 0px;
			}
		}
	}
}

.overflow-x-hidden {
	overflow-x: hidden;
}

@include media-breakpoint-down(md) {
	* {
		-webkit-user-select: none; /* Safari */        
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* IE10+/Edge */
		user-select: none; /* Standard */
	}
}

.action-link {
	color: $blue-500;
	text-decoration: none;

	&:hover {
		cursor: pointer;
	}
}

.disclaimer-text {
	opacity: 0.5;
}

.info-circle {
	color: $finetic-gray-500;
}
