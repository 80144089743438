// ==========================================================================
// Page – Interactive Chart
// ==========================================================================

.chart-container {
	height: calc(100vh - 54px - 50px - 0.5rem - env(safe-area-inset-top) - env(safe-area-inset-bottom))!important;

	@include media-breakpoint-down(md) {
		height: calc(100vh - 72px - 50px - 0.5rem - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
	}

	#tv_chart_container {
		height: 100%;
	}
}

@include media-breakpoint-down(md) {
	.interactive-chart-mobile-header-override {
		display: none;
	}

	.interactive-chart-mobile-body-override {
		padding-top: 0px!important;
	}
}
