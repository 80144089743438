// ==========================================================================
// Common - Products Shared
// ==========================================================================

#sort-direction:checked {
	background-color: $white;
	border-color: rgba(0, 0, 0, 0.25);
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.positive-growth {
	color: $positive-color;
}

.negative-growth {
	color: $negative-color;
}

.market-selector {
	position: fixed;
	bottom: calc(74px + env(safe-area-inset-bottom));
	right: 10px;
	background-color: $white;
	border-radius: 50%;
	height: 56px;
	width: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 2px 6px rgba($black, 0.3);
	cursor: pointer;

	a {
		color: $black;
		text-decoration: none;
	}

	.selector-bg-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		overflow: hidden;
		border-radius: 50%;

		.selector-bg-overlay {
			width: 100%;
			-webkit-filter: blur(30px);
			filter: blur(30px);

			.selector-bg-text {
				font-size: 300%;
			}
		}
	}

	.selector-label {
		font-size: 1.5em;
	}

	.overlay-dot {
		position: absolute;
		z-index: 10;
		top: -5px;
		right: 3px;
		width: 1em!important;
		height: 1em!important;
	}

	.dot {
		width: 0.8em;
		height: 0.8em;
		border-radius: 50%;
		background-color: rgb(255, 69, 48);
	}
}

.forbidden-img {
	width: 15em;
}

@media (max-width: 991.98px) {
	.forbidden-img {
		width: 10em;
	}
}
