// ==========================================================================
// Component – Tables
// ==========================================================================

.table {
	font-variant-numeric: tabular-nums;

	thead {

		th.sortable {
			cursor: pointer;
		}

		th > div.sortable {
			cursor: pointer;
		}
	}

	tbody {
		tr {
			&:hover {
				background-color: $finetic-gray-300;
			}
		}
		
		tr, th, td {
			&.clickable {
				cursor: pointer;
			}
		}

		tr:last-child {
			td, th {
				border-bottom: none;
			}
		}
	}
}

.data-table {
	font-size: clamp(0.8em, 0.7vw, 0.7vw);
	background-color: $white;
	border-radius: 10px;

	thead {
		position: sticky;
		top: 0;
		z-index: 10;
		background-color: $finetic-blue-100;

		th {
			white-space: nowrap;
			padding-left: 1rem;
			padding-right: 1rem;

			svg {
				color: rgba($black, 0.4);

				&.active {
					color: rgba($black, 0.8);
				}
			}
		}

		th:first-child::before, th:last-child::after {
			width: 1px;
			height: 5px;
			background: white;
			content: "";
			display: block;
			position: absolute;
			top: 0px;
		}
		
		th:first-child::before {
			left: -1px;
		}

		th:last-child::after {
    		right: -1px;
		}
	}

	tbody {
		th, td {
			white-space: nowrap;
			padding-left: 1rem;
			padding-right: 1rem;
			vertical-align: middle;
		}
	}
}
