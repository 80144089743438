// ==========================================================================
// Page - User Dashboard
// ==========================================================================

#referral-source-q-title:after {
	content: "*";
	color: var(--danger-color);
	margin-left: .3rem;
}

.user-info {
	.user-info-row {
		height: 2.5em;
	}

	.user-info-text-field {
		border-radius: 5px;
		border: none;
		padding: 0.25em 0.5em;
		overflow: hidden;
	}

	.PhoneInputInput {
		display: block;
		width: 100%;
		height: var(--input-height);
		padding-left: var(--input-horizontal-padding);
		padding-right: var(--input-horizontal-padding);
		color: var(--lm-input-text-color);
		background-color: var(--lm-input-bg-color);
		border: var(--input-border-width) solid var(--lm-input-border-color);
		border-radius: var(--input-border-radius);
		box-shadow: var(--lm-input-box-shadow);
		-webkit-appearance: none;
		appearance: none;

		&:hover {
			color: var(--lm-input-text-color-hover);
			background-color: var(--lm-input-bg-color-hover);
			border-color: var(--lm-input-border-color-hover);
			box-shadow: var(--lm-input-box-shadow-hover);
		}
	}

	.user-info-edit-icon {
		cursor: pointer;

		&:hover {
			color: $white;
		}
	}
}

@media (min-width: 992px) {
	.user-info {
		max-width: 450px;
	}
}

.user-dashboard-tabs {
	color: $white;
	padding: 0.5em 0;

	div {
		display: inline-block;
		padding: 0.5em 1em;
		cursor: pointer;
		border-right: 2px solid $white;

		&:hover {
			background-color: rgba($white, 0.3);
		}

		&.active {
			background-color: rgba($white, 0.2);
		}
	}
}

.dashboard-card {
	.user-info-edit-icon {
		color: $finetic-gray-500;
		cursor: pointer;

		&:hover {
			color: $black;
		}
	}
}

.subscription {
	cursor: pointer;

	.user-info-edit-icon {
		color: $finetic-gray-500;
	}

	&:hover {
		.user-info-edit-icon {
			color: $black;
		}
	}
}

.subscription-detail {
	.billing-period-selection {
		width: 30%;
		border: 3px solid $finetic-blue-500;
		border-radius: 5px;
		padding: 1em;
		color: $finetic-blue-500;
		display: flex;
		flex-direction: column;
		text-align: center;
		cursor: pointer;

		&.active-plan {
			background-color: $finetic-blue-500;
			color: white;
			cursor: default;

			&:hover {
				background-color: $finetic-blue-500;
				cursor: default;
			}
		}

		&:hover {
			background-color: rgba($white, 0.7);
			cursor: pointer;
		}

		&.future-plan:hover {
			background-color: transparent;
			cursor: default;
		}

		.badge {
			margin-top: 0.75em;
			font-size: 0.6em;
			// color: white;
			text-transform: uppercase;
		}
	}

	@media (max-width: 991.98px) {
		.billing-period-selection {
			padding: 0.5em;
		}
	}
}

.subscribe-link {
	color: $finetic-gray-500;
	text-decoration: underline;

	&:hover {
		color: $black;
		text-decoration: none;
	}
}

.notification-info {
	color: $finetic-gray-500;
}

.notification-toggle-tooltip:before {
	transform: translate(-100%, 0%) rotate(45deg)!important;
}

.notification-switch {
	input[type=checkbox]:checked~label:before {
		background-color: $finetic-green-500;
		border-color: $finetic-green-700;
	}

	input[type=checkbox]:checked:disabled~label:before, input[type=checkbox]:checked:hover:disabled~label:before {
		background-color: $finetic-green-500;
		border-color: $finetic-green-700;
	}

	input[type=checkbox]:checked~label:after {
		background-color: rgba(0, 0, 0, .6);
	}
}

.affiliate-stat {
	font-size: 2em;
}
