// ==========================================================================
// Component – Blur Glass
// ==========================================================================

$blur-saturation-disabled: 50%;
$blur-saturation: 180%;
$blur-saturation-heavy: 200%;

@mixin bg-blur-glass-heavy {
	-webkit-backdrop-filter: saturate($blur-saturation-heavy) blur(50px);
	backdrop-filter: saturate($blur-saturation-heavy) blur(50px);
}


@mixin bg-blur-glass {
	-webkit-backdrop-filter: saturate($blur-saturation) blur(20px);
	backdrop-filter: saturate($blur-saturation) blur(20px);
}

@mixin bg-blur-glass-disabled {
	-webkit-backdrop-filter: saturate($blur-saturation-disabled) blur(20px);
	backdrop-filter: saturate($blur-saturation-disabled) blur(20px);
}

@mixin bg-blur-glass-light {
	-webkit-backdrop-filter: saturate($blur-saturation) blur(10px);
	backdrop-filter: saturate($blur-saturation) blur(10px);
}

@mixin bg-blur-glass-light-disabled {
	-webkit-backdrop-filter: saturate($blur-saturation-disabled) blur(10px);
	backdrop-filter: saturate($blur-saturation-disabled) blur(10px);
}

@mixin bg-blur-glass-xlight {
	-webkit-backdrop-filter: saturate($blur-saturation) blur(5px);
	backdrop-filter: saturate($blur-saturation) blur(5px);
}

@mixin bg-blur-glass-xlight-disabled {
	-webkit-backdrop-filter: saturate($blur-saturation-disabled) blur(5px);
	backdrop-filter: saturate($blur-saturation-disabled) blur(5px);
}

.bg-blur-glass {
	@include bg-blur-glass;
}

.bg-blur-glass-disabled {
	@include bg-blur-glass-disabled;
}
