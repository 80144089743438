// ==========================================================================
// Page – FinTrainer
// ==========================================================================

.fintrainer {
	padding-top: env(safe-area-inset-top);
	padding-bottom: env(safe-area-inset-bottom);
}

.leaderboard-modal, .game-modal {
	max-height: 80%;
	height: auto;
}

.leaderboard-type-toggle {
	cursor: pointer;
	border-radius: 5px;
	padding: 0.15em 0.3em;

	&.active {
		border: 1px solid #212121;
		font-weight: bold;
	}
	
	&.inactive {
		color: text-color(dark);
	}
}

.game-leaderboard-table {
	thead {
		th {
			font-size: 0.8em;
			padding-bottom: 5px!important;
		}

		th:nth-child(1) {
			width: 10%;
		}

		th:nth-child(2) {
			width: 30%;
		}

		th:nth-child(3), th:nth-child(4) {
			width: 30%;
		}
	}

	tbody {
		th, td {
			padding-top: 5px!important;
			padding-bottom: 5px!important;
		}

		th:nth-child(1) {
			width: 10%;
		}

		td:nth-child(2) {
			width: 30%;
		}

		td:nth-child(3), td:nth-child(4) {
			width: 30%;
		}
	}

	.first {
		color: #FFD700;
	}

	.second {
		color: #C0C0C0;
	}

	.third {
		color: #CD7F32;
	}
}

.simulator-game-chart {
	height: calc(100vh - 54px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.simulator-panel {
	font-size: 0.9em;
}

@media (max-width: 769px) {
	.simulator-game-chart {
		height: 55vh;
	}

	.simulator-game-info {
		font-size: 0.8em;

		.simulator-game-stats {
			b {
				font-weight: normal;
				color: #555;
			}
		}
	}

	.game-button-outline {
		background: none!important;
		color: color(blue)!important;
		border: 2px solid color(blue);

		&:hover {
			color: color(blue, light)!important;
			border-color: color(blue, light);
		}
	}
}

@include media-breakpoint-down(md) {
	.fintrainer-mobile-header-override {
		display: none;
	}

	.fintrainer-mobile-body-override {
		padding-top: 0px!important;
	}
	
	.fintrainer-mobile-main-content-override {
		padding-bottom: env(safe-area-inset-bottom)!important;
	}
}
