// ==========================================================================
// Page – Watchlist
// ==========================================================================

.watchlist-tabs {
	overflow-x: auto;
	overflow-y: hidden;
	flex-wrap: nowrap;

	.nav-item {
		white-space: nowrap;
	}
}

#stock-code-sm {
	appearance: none;
	-webkit-appearance: none;
}

.add-stockcode-input {
	height: 3.2rem;
	border-radius: 0.3rem;
	padding: 0 0.5em;
	border: 1px solid $finetic-gray-300;
}

.watchlist-action {
	cursor: pointer;
	color: $finetic-gray-500;
}

.edit-watchlist-stock-icon:hover {
	color: $finetic-orange-500;
}

.remove-from-watchlist-icon:hover {
	color: red;
}

.watchlist-table {
	thead {
		> tr > {
			th:nth-child(1) {
				max-width: 300px;
			}

			th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6), th:nth-child(7), th:nth-child(8) {
				width: 9%;
			}
		}
	}

	tbody {
		> tr > {
			td:nth-child(1) {
				max-width: 300px;

				div {
					display: block;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6), td:nth-child(7), td:nth-child(8) {
				width: 9%;
			}
		}
	}
}

.watchlist-chart {
	max-width: 100%;
	height: 100%;
}

.watchlist-overlay {
	.offcanvas-body {
		.watchlist-chart {
			height: 50vh!important;
		}
	}
}

.watchlist-stock-card {
	background-color: $white;
	border-radius: 5px;

	&.expanded {
		background: none;
	}

	.ticker {
		.stock-code {
			font-weight: bold;
		}

		.stock-name {
			font-size: 0.9em;
		}
	}

	.details {
		font-size: 0.8em;
		cursor: pointer;

		&.expanded {
			cursor: default;
		}

		.label {
			color: rgba(black, 0.6);
		}
	}

	.expand-chevron {
		color: $finetic-gray-500;
	}
}

.watchlist-metadata {
	color: $finetic-gray-500;
}

.watchlist-search {
	/**
	 * Main wrapper
	 */
	.select-search {
		position: relative;
		box-sizing: border-box;
	}

	.select-search *,
	.select-search *::after,
	.select-search *::before {
		box-sizing: inherit;
	}

	/**
	* Value wrapper
	*/
	.select-search__value {
		position: relative;
		z-index: 1;
	}

	.select-search__value::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: calc(50% - 9px);
		right: 19px;
		width: 11px;
		height: 11px;
	}

	/**
	* Input
	*/
	.select-search__input {
		display: block;
		width: 100%;
		padding: 0 40px 0 16px;
		background: #fff;
		border: 1px solid transparent;
		box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
		border-radius: 3px;
		outline: none;
		font-size: 14px;
		text-align: left;
		text-overflow: ellipsis;
		line-height: 36px;
		-webkit-appearance: none;
	}

	.select-search__input::-webkit-search-decoration,
	.select-search__input::-webkit-search-cancel-button,
	.select-search__input::-webkit-search-results-button,
	.select-search__input::-webkit-search-results-decoration {
		-webkit-appearance:none;
	}

	.select-search__input:not([readonly]):focus {
		cursor: initial;
	}

	/**
	* Options wrapper
	*/
	.select-search__select {
		background: #fff;
		box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
	}

	/**
	* Options
	*/
	.select-search__options {
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
	}
	
	.select-search__row {
		margin-bottom: 0;
	}

	.stock-option {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		border: none;
		outline: none;
		width: 100%;
		background-color: white;
	}

	.stock-option.is-selected {
		background: $blue-500;
		color: $white;
	}
	
	.stock-option.is-highlighted,
	.stock-option:not(.is-selected):hover {
		background: $blue-100;
	}
	
	.stock-option.is-highlighted.is-selected,
	.stock-option.is-selected:hover {
		background: $blue-100;
	}

	/**
	* Modifiers
	*/
	.select-search--multiple {
		border-radius: 3px;
		overflow: hidden;
	}

	.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
		transform: rotate(45deg);
		border-right: 1px solid #000;
		border-bottom: 1px solid #000;
		pointer-events: none;
	}

	.select-search--multiple .select-search__input {
		cursor: initial;
	}

	.select-search--multiple .select-search__input {
		border-radius: 3px 3px 0 0;
	}

	.select-search--multiple:not(.select-search--search) .select-search__input {
		cursor: default;
	}

	.select-search:not(.select-search--multiple) .select-search__input:hover {
		border-color: $blue-500;
	}

	.select-search:not(.select-search--multiple) .select-search__select {
		position: absolute;
		z-index: 20;
		top: 44px;
		right: 0;
		left: 0;
		border-radius: 3px;
		overflow: auto;
		max-height: 360px;
	}

	@media (max-width: 991.98px) {
		.select-search:not(.select-search--multiple) .select-search__select {
			max-height: 225px;
		}
	}

	.select-search--multiple .select-search__select {
		position: relative;
		overflow: auto;
		max-height: 260px;
		border-top: 1px solid #eee;
		border-radius: 0 0 3px 3px;
	}

	.select-search__not-found {
		height: auto;
		padding: 16px;
		text-align: center;
		color: #888;
	}
}
