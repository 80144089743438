// ==========================================================================
// Page – Stock News
// ==========================================================================

.stock-news {
	.stock-news-in-finlist {
		background-color: rgba($finetic-yellow-500, 0.3);
	}

	.stock-news-indicator {
		border-radius: 10px;
		width: 10px;
		height: 10px;

		&.positive {
			background-color: $positive-color;
		}

		&.negative {
			background-color: $negative-color;
		}
	}
}
