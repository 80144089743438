// ==========================================================================
// Component – Sidebar
// ==========================================================================

#sidebar-finetic {
	width: 100%;
	height: calc(100vh - 54px - env(safe-area-inset-bottom));
	overflow-y: auto;
	background-color: $finetic-blue-500;
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */

	.sidebar-nav {
		.sidebar-category {
			color: $white;
			cursor: pointer;
			margin: 0px 5px;

			.chevron {
				display: flex;
				justify-content: center;
				width: 20px;
				transition: transform .35s ease;

				&.active {
					transform: rotate(90deg);
				}
			}

			&:hover {
				border-radius: 5px;
				background-color: rgba($white, 0.2);
			}
		}

		.sidebar-item {
			color: rgba($white, 0.8);
			text-decoration: none;
			margin: 5px 5px;
			padding-left: 20px;

			&:hover {
				border-radius: 5px;
				background-color: rgba($white, 0.1);
			}

			&.active {
				cursor: default;
				color: $white;
				border-radius: 5px;
				background-color: rgba($white, 0.2);
			}
		}
	}

	.footer {
		color: $white!important;
		font-size: 0.9em;
		opacity: 0.5;

		padding-left: 25px;

		a {
			color: $white;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.icon {
		width: 25px;
		text-align: center;
	}
}
