// ==========================================================================
// Page – Market Trend
// ==========================================================================

.market-trend {
	.trends {
		flex: 2 1;
	}
	
	.snapshot-card {
		flex: 1 1;
	}
	
	.main-trend-card {
		overflow: hidden;
		height: 8vw;
		border-radius: 10px;
		position: relative;

		.background-image-wrapper {
			position: absolute;
			top: -250%;
			left: 0;
			bottom: 0;
			right: 0;
			overflow: hidden;

			.background-image-overlay {
				width: 100%;
				opacity: 0.7;
				-webkit-filter: blur(30px);
				filter: blur(30px);

				img {
					width: 100%;

					// @include media-breakpoint-down(lg) {
					// 	height: 12vw;
					// }
				}
			}

			@include media-breakpoint-down(md) {
				top: -200%;
			}
		}

		.trend-img-wrapper {
			width: 5vw;
			height: 5vw;
		}
	
		.trend-img {
			border-radius: 5px;
			border: 1px solid;
			width: 5vw;
			height: 5vw;
		}
	
		.trend-info {
			height: 8vw;
	
			.term-key {
				font-size: 0.9em;
			}
	
			.trend-key {
				font-size: 1.25em;
			}
		}
	
		@include media-breakpoint-down(lg) {
			height: 12vw;

			.trend-info {
				height: 12vw;
			}
		}
	
		@include media-breakpoint-down(md) {
			height: 25vw;
			border-radius: 0px;
	
			.trend-img {
				height: 15vw;
				width: 15vw;
			}

			.trend-info {
				height: 25vw;
			}
		}
	}
	
	.secondary-trend-card {
		.trend-img-wrapper {
			width: 2.5vw;
			height: 2.5vw;
		}
	
		.trend-img {
			border-radius: 5px;
			border: 1px solid;
			width: 2.5vw;
			height: 2.5vw;
		}
	
		.trend-key {
			font-size: 0.8em;
		}
	
		@include media-breakpoint-down(md) {
			& {
				.trend-img {
					height: 10vw;
					width: 10vw;
				}
			}
		}
	}
	
	.trend-img {
		&.uptrend {
			border-color: $finetic-green-500;
		}
	
		&.downtrend {
			border-color: $finetic-orange-300;
		}
	
		&.under-pressure, &.rally-attempt {
			border-color: $finetic-blue-300;
		}
	}
	
	.trend-since, .trend-last-updated {
		font-size: 0.6em;
		white-space: nowrap;
	}
	
	.trend-last-updated {
		color: $finetic-gray-700;
	}
	
	.snapshot-card {
		.snapshot-text {
			font-size: 0.8em;
		}
	}
	
	.chart-container {
		min-height: 30vh;
		max-height: 60vh;
		height: 50vh;
		border-radius: 10px;
		overflow: hidden;
	
		article {
			> div {
				position: unset!important;
			}
		}
	}
	
}
