// ==========================================================================
// Page – Subscribe
// ==========================================================================

.subscription-modal {
	.note {
		font-size: 0.8em;
	}

	.coupon {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.coupon-saved {
			font-size: 0.9em;
		}

		.coupon-dismiss {
			cursor: pointer;
		}
	}

	.express-checkout {
		.title {
			font-size: 0.9em;
			display: flex;
			width: 100%;
			line-height: 100%;
			justify-content: center;
			align-items: flex-end;
			text-align: center;

			&::before, &::after {
				content: '';
				border: 1px $black solid;
				border-bottom: 0;
				height: 0.5em;
				flex: 1 0 2em;
			}

			&::before {
				border-right: 0;
				border-top-left-radius: 5px;
				margin-right: 1em;
			}

			&::after {
				border-left: 0;
				border-top-right-radius: 5px;
				margin-left: 1em;
			}
		}
		
		.payment-methods {
			border: 1px $black solid;
			border-top: 0;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			padding: 0.5em;
		}
	}

	.payment-separator {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		line-height: 100%;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 1.5em;

		span {
			font-size: 0.9em;
			padding: 0 1em;
		}

		&::before, &::after {
			content: '';
			border: 1px $black solid;
			border-bottom: 0;
			height: 0.5em;
			flex: 1 0 2em;
			border-right: 0;
			border-left: 0;
		}
	}

	.card-elements-wrapper {
		background-color: $finetic-gray-300;
		border-radius: 5px;
	}
}

.billing-period-active {
	font-weight: bold;
}

.manage-subscription:hover {
	text-decoration: none;
}

.plans-table-wrapper {
	overflow-x: scroll;

	.plans-table {
		th {
			background-color: $finetic-gray-300;
		}

		thead {
			th {
				font-weight: normal;
				min-width: 270px;
				max-width: 300px;
				
				&:first-child {
					position: sticky;
					left: 0;
					z-index: 2;
					border-right: 1px solid $finetic-gray-500;
					border-radius: 0;
				}

				&.billing-period {
					min-width: 330px;
					max-width: 450px;
				}

				.plan-price {
					margin: 1em 0;
				}

				.manage-subscription {
					text-decoration: none;
				}

				.plan-currency .plan-price-per-month {
					margin-bottom: -5px;
				}
			}
		}

		tbody {
			tr.feature-row {
				&:hover {
					th, td {
						background-color: $finetic-green-300;
					}
				}

				th {
					max-width: 450px;
					position: sticky;
					left: 0;
					background-color: $finetic-gray-300;
					border-right: 1px solid $finetic-gray-500;
					border-top: 1px solid $finetic-gray-500;
					z-index: 1;
				}

				td {
					border-top: 1px solid $finetic-gray-500;
					text-align: center;

					svg {
						color: $finetic-green-700;
					}

					.not-featured {
						opacity: 0.38;
					}
				}
			}
		}
	}
}
