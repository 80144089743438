// ==========================================================================
// Page – Shareholding Change data
// ==========================================================================

.shareholding-change-last-updated {
	font-size: 0.8em;
	color: $finetic-gray-700;
}

.shareholding-change-table {
	font-size: 0.8em;

	> thead {
		th:nth-child(2) {
			text-align: right;
		}
	}

	> tbody {
		td:nth-child(2) {
			text-align: right;
		}
	}
}

.shareholding-change-row {
	font-size: 0.8em;
}

.shareholding-change-desc {
	font-size: 0.8em;
}
