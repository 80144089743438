// ==========================================================================
// Component – Navbar
// ==========================================================================

#finetic-navbar {
	background-color: $finetic-blue-500;
	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
	border-top: none!important;
	padding-bottom: max(env(safe-area-inset-bottom), 0.5rem);

	.nav-bar-justify-content-between {
		a {
			width: 20%;
		}
	}

	a {
		text-decoration: none;
	}

	.tab {
		position: relative;
		padding: 0.5em 0.75em;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: rgba($white, 0.38);
		text-decoration: none;

		&.active {
			color: $white;
		}
			
		span {
			font-size: max(10px, 0.5em);
			padding-top: 0.5em;
		}

		.dot {
			position: absolute;
			top: 2px;
			right: 17px;
			width: 0.7em;
			height: 0.7em;
			border-radius: 50%;
			background-color: rgb(255, 69, 48);
		}
	}
}
